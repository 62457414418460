declare var $: any;
import { Injectable } from '@angular/core';

// @ts-ignore
import baron = require('../assets/js/baron');
// @ts-ignore
import device = require('../assets/js/device.min');

/**
 * Применяется для работы сторонних JQuery скриптов не являющихся частью Angular
 * @date 10.04.2023 - 11:55:53
 *
 * @export
 * @class JQ
 * @typedef {JQ}
 */
@Injectable()

export class JQ {
  constructor() {}

  my = function(id){
   // @ts-ignore
    ym(+id, "init", {
      // defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });
    console.log('Run ym!!!!', id);
  }

  // jivo = function(id){
  //   let s = document.createElement('script',);
  //   s.type = 'text/javascript';
  //   s.async = true;
  //   s.src = '//code.jivosite.com/script/widget/' + id;

  //   let ss = document.getElementsByTagName('script')[0];
  //   if(ss && !ss.src.includes('' + id)){
  //     ss.parentNode.insertBefore(s, ss);
  //   }
  // };

  // обрезка троеточием елементов .textTailor
  textTailor = function(){
    $(document).ready(function(){
      setTimeout(function(){
        $('.textTailor').textTailor({lineHeight: 1.375, fit: false, ellipsis: true});
      }, 300);
    });
  };

  isMobile = function(){
    return device.mobile();
  }
  // фиксированная фапка
  fixHeader = function(){
    // показ / скрытие фиксированной шапки
    $(document).ready(function(){
      const headerFix = $('.header-fix');
      $(window).scroll(function(){
        if ($(window).scrollTop() > 100) {
          headerFix.addClass('js--show');
        } else {
          headerFix.removeClass('js--show');
        }
      });
      $(window).scroll();
    });
  }

  // слайдер изображений
  bigSlider = function(){
    $(document).ready(function(){
      $('.big-slider').each(function(i, t){
        $(t).next('.big-slider-nav').attr('id', 'big-slider-nav-' + i);
        $(t).attr('id', 'big-slider-' + i).on('init', function(){
          setTimeout(function(){
            $('.textTailor').textTailor({lineHeight: 1.375, fit: false, ellipsis: true});
            $(t).find('._video, ._image').each(function(i2, t2){
              $(t2).width($(t2).find('img').width());
            });
          }, 20);
        });
        if (!$(t).hasClass('big-slider--one-slide')) {
          $(t).slick({
            variableWidth: true,
            appendArrows: '#big-slider-nav-' + i,
            appendDots: '#big-slider-nav-' + i,
            dots: true,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 3000,
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
            prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
            nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false
                }
              }
            ]
          });
        } else {
          $(t).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: '#big-slider-nav-' + i,
            appendDots: '#big-slider-nav-' + i,
            dots: true,
            adaptiveHeight: true,
            prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
            nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false
                }
              }
            ]
          });
        }
      });
    });
  }

  // слайдер отзывов + модальное окно + скролинг
  feedbackSlider = function(){
    $(document).ready(function(){
      $('.slider-feedbacks').on('init', function(){
        setTimeout(function(){
          $('.slider-feedbacks .feedback__text').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 20);
      })
        .slick({
        infinite: false,
        rows: 1,
        slidesToShow: 3,
        slidesToScroll: 3,
        appendArrows: '.feedback__slider-nav',
        prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
        nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false
            }
          }
        ]
      });
      $('.slider-feedbacks-fullsize').on('init', function(){
        setTimeout(function(){
          $('.slider-feedbacks-fullsize .feedback__text').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 20);
      }).on('afterChange', function(){
        // tslint:disable-next-line:max-line-length
        $('.slider-feedbacks-fullsize .slick-center .feedback__text--full').height($('.slider-feedbacks-fullsize .slick-center .feedback__text-wrap').outerHeight());
      })
        .slick({
        variableWidth: true,
        infinite: false,
        speed: 300,
        centerMode: true,
        arrows: false
      });

      $('[href="#center-feedbacks"]').click(function(){
        setTimeout(function(){
          $('.feedbacks-row .feedback__text').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 500);
      });

      setTimeout(function(){
        $('.feedbacks-row .feedback__text').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
      }, 500);

      let startX;
      let xDistance;
      $('.slider-feedbacks .feedback-wrap, .feedbacks-row .feedback-wrap').on('touchstart mousedown', function(e){
        startX = e.clientX;
      });

      $('.slider-feedbacks .feedback-wrap, .feedbacks-row .feedback-wrap').on('touchend mouseup', function(e){
        xDistance = startX - e.clientX;
        // tslint:disable-next-line:prefer-const
        let t;
        if (Math.abs(xDistance) < 30) {
          $('body').addClass('js--feedbacks-open');
          $('.slider-feedbacks-fullsize').slick('slickGoTo', $(this).prevAll().length);
          t = $('.slider-feedbacks-fullsize .slick-active .feedback__text--full');
          t.height(t.outerHeight());
        }
      });

      $('.feedback-close').click(function(){
        $('body').removeClass('js--feedbacks-open');
      });
    });
  }

//Отзывы в мобильном виде
  feedbackSlider_m = function(){
    $(document).ready(function(){
      $('.slider-feedbacks_m').on('init', function(){
        setTimeout(function(){
          $('.slider-feedbacks_m .feedback__text_m').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 20);
      })
        .slick({
        infinite: false,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '16px',
      });
      $('.slider-feedbacks-fullsize').on('init', function(){
        setTimeout(function(){
          $('.slider-feedbacks-fullsize .feedback__text').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 20);
      }).on('afterChange', function(){
        // tslint:disable-next-line:max-line-length
        $('.slider-feedbacks-fullsize .slick-center .feedback__text--full').height($('.slider-feedbacks-fullsize .slick-center .feedback__text-wrap').outerHeight());
      })
        .slick({
        variableWidth: true,
        infinite: false,
        speed: 300,
        dots: true,
        centerMode: true,
        appendArrows: '.slider-feedbacks-fullsize-nav',
        appendDots: '.slider-feedbacks-fullsize-nav',
        prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
        nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
      });

      $('[href="#center-feedbacks"]').click(function(){
        setTimeout(function(){
          $('.feedbacks-row .feedback__text_m').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 500);
      });

      setTimeout(function(){
        $('.feedbacks-row .feedback__text_m').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
      }, 500);

      let startX;
      let xDistance;
      $('.slider-feedbacks_m .feedback-wrap_m, .feedbacks-row .feedback-wrap_m').on('touchstart mousedown', function(e){
        startX = e.clientX;
      });

      $('.slider-feedbacks_m .feedback-wrap_m, .feedbacks-row .feedback-wrap').on('touchend mouseup', function(e){
        xDistance = startX - e.clientX;
        // tslint:disable-next-line:prefer-const
        let t;
        if (Math.abs(xDistance) < 30) {
          $('body').addClass('js--feedbacks-open');
          $('.slider-feedbacks-fullsize').slick('slickGoTo', $(this).prevAll().length);
          t = $('.slider-feedbacks-fullsize .slick-active .feedback__text--full');
          t.height(t.outerHeight());
        }
      });

      $('.feedback-close').click(function(){
        $('body').removeClass('js--feedbacks-open');
      });
    }
    );
  }
  // слайдер Докторов на главной
  doctorsSlider = function(){
    $(document).ready(function(){
      $('.mp-doctors__slider-items').slick({
        rows: 1,
        infinite: false,
        slidesPerRow: 3,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        appendArrows: '.mp-doctors__slider-nav',
        prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
        nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              rows: 1,
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1200,
            settings: {
              rows: 1,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '17px',
            }
          }
        ]
      });
    });
  }
  // doctorsSliderEN = function(){
  //   $(document).ready(function() {
  //     $('.mp-english .mp-doctors__slider-items').slick('unslick').slick({
  //       rows: 4,
  //       infinite: false,
  //       slidesPerRow: 3,
  //       speed: 300,
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       appendArrows: '.mp-doctors__slider-nav',
  //       prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
  //       nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
  //       responsive: [
  //         {
  //           breakpoint: 1800,
  //           settings: {
  //             rows: 4,
  //             slidesPerRow: 3,
  //           }
  //         },
  //         {
  //           breakpoint: 992,
  //           settings: {
  //             variableWidth: true,
  //             rows: 3,
  //             slidesPerRow: 1,
  //           }
  //         },
  //         {
  //           breakpoint: 768,
  //           settings: {
  //             variableWidth: true,
  //             rows: 2,
  //             slidesPerRow: 1,
  //             dots: true
  //           }
  //         },
  //       ]
  //     });
  //   })
  // };

  // слайдер Статьи-Новости-Видео
  articlesSlider = function(){
    $(document).ready(function(){
      $('.slider-articles').on('init', function(){
        setTimeout(function(){
          $('.article__info-title').textTailor({lineHeight: '24px', fit: false, ellipsis: true});
          $('.article__info-text').textTailor({lineHeight: '22px', fit: false, ellipsis: true});
        }, 20);
      }).slick({
        variableWidth: true,
        infinite: false,
        appendArrows: '.news__slider-nav',
        prevArrow: '<div class="slick-prev"><svg><use xlink:href="#arrow-prev"></use></svg></div>',
        nextArrow: '<div class="slick-next"><svg><use xlink:href="#arrow-next"></use></svg></div>',
        responsive: [
          // {
          //   breakpoint: 768,
          //   settings: {
          //     dots: true,
          //     arrows: false
          //   }
          {
            breakpoint: 768,
            settings: {
              dots: false,
              arrows: false,
              slidesToScroll: 1
            }
          }
        ]
      });
    });
  }

  footerSlider = function(){
    $(document).ready(function(){
      $('.footer__slider').slick({
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        variableWidth: true,
        dots: false,
        arrows:false,
      });
    });
  }

  mapModule = function(){
    $(document).ready(function(){
      // MAP module
      $('.map__address').click(function(){
        $(this).addClass('active').siblings().removeClass('active');
      });
    });
  }

  // directionPage = function(){
  //   $(document).ready(function(){
  //     // Directions page
  //     $('.directions-item__title').click(function(){
  //       $(this).next().slideToggle();
  //     });
  //   });
  // }

  openMainMenu = function() {
    $(document).ready(function () {
      // переключатель меню
      $('.js--open-mainmenu').click(function () {
        $('body').toggleClass('js--open-menu');
        if ($('body').hasClass('js--open-menu') && device.mobile()) {
          $('.mainmenu-search input').focus();
        }
        $('body').removeClass('js--active-results');
        $('.header-search input, .mainmenu-search input, .mobilemenu-search input').val('');
      });

      $('.js--mobilemenu-open').click(function (e) {
        e.preventDefault();
        $('body').addClass('js--mobilemenu-inner-opened');
        $(this).next('.mobilemenu-nav__inner').addClass('js--inner-opened');
      });

      $('.mobilemenu-nav__inner-back').click(function () {
        $('body').removeClass('js--mobilemenu-inner-opened');
        $(this).closest('.mobilemenu-nav__inner').removeClass('js--inner-opened');
      });
      $('.mobilemenu-close, .mobilemenu-order, .mainmenu-order').click(function () {
        $('body').removeClass('js--mobilemenu-inner-opened');
        $(this).closest('.mobilemenu-nav__inner').removeClass('js--inner-opened');
        $('body').removeClass('js--open-menu');
      });

      $('._search').click(function() {
        $(this).closest('.mp').removeClass('js--open-menu');
      });

      $('.form-control').keypress(function(event) {
        // console.log('= press key =', event.which);
        if (event.which === 13)
        {
          $(this).closest('.mp').removeClass('js--open-menu');
        }
      });
    });
  };

  ie11 = function(){
    $(document).ready(function(){
      // определяем ie 11
      const doc = document.documentElement;
      doc.setAttribute('data-useragent', navigator.userAgent);
    });
  }

  headerSearch = function(){
    $(document).ready(function(){
      // Увеличение строки поиска по ширине
      $('.header-search input').focus(function(){
        $('.header').addClass('js--active-search');
      });
      $('.header-search input').blur(function(){
        $('.header').removeClass('js--active-search');
      });

      // Показ подсказки поиска при вводе
      // $('.header-search input, .mainmenu-search input, .mobilemenu-search input').on('input', function() {
      //   if (this.value !== '') {
      //     $('body').addClass('js--active-results');
      //   } else {
      //     $('body').removeClass('js--active-results');
      //   }
      // });
      // закрытие меню при вводе на строке поиска
      $('.mobilemenu-search input').keydown(function( event ){
        console.log( event.which );
      });

      $('.header-search input, .mainmenu-search input, .mobilemenu-search input').on('focus, input', function(){
        $('.mainmenu, .mobilemenu').animate({scrollTop: '0'});
      });

      $('.js--clear-search').click(function(){
        $('.header-search input, .mainmenu-search input, .mobilemenu-search input').val('');
        $('body').removeClass('js--active-results');
      });

      // закрытие и очистка поиска по ESC
      $(document).keydown(function(e){
        if (e.keyCode === 27) {
          $('body').removeClass('js--active-results');
          $('.header-search input, .mainmenu-search input, .mobilemenu-search input').val('');
        }
      });
    });
  }

  startVideo = function(){
    $(document).ready(function(){
      // Включение видео
      $('.video-cover').click(function(){
        $(this).fadeOut().next('video').get(0).play();
      });
    });
  }

  // "запись на прием"
  apointmentDocOrService = function(){
    $(document).ready(function(){
      $('.js--apointment-doctor-or-service').change(function() {
        const time = 600;
        const inner = $(this).closest('.apointment-step__inner');
        if (this.value === 'service') {
          inner.find('._for-doctor').slideUp(time);
          setTimeout(function(){
            inner.find('._for-service').slideDown(time);
          }, time);
        } else {
          inner.find('._for-service').slideUp(time);
          setTimeout(function(){
            inner.find('._for-doctor').slideDown(time);
          }, time);
        }
      });

      $('.js--openApointmentStep3').click(function(){
        $('#apointment-step-3 .apointment-step__inner').slideDown();
        $(this).parent().slideUp();
      });

      $('.js--show-success-block').click(function(){
        $('.apointment-form').slideUp();
        $('.apointment-success').slideDown();
        $('html, body').animate({ scrollTop: '0' });
      });
    });
  }

  apointmentSetTypeInsurance = function(){
    $(document).ready(function(){
      $('[name="type-insurance"]').change(function(){
        // @ts-ignore
        if (this.value === 'oms') {
          $('#apointment-step-2').slideUp().removeClass('show');
          $('#apointment-step-3').slideUp().removeClass('show');
          $('#apointment-step-OMS').slideDown().addClass('show');
          // @ts-ignore
        } else if (this.value === 'dms') {
          $('#apointment-step-2').removeClass('disabled');
          $('#apointment-step-OMS').slideUp().removeClass('show');
          $('#apointment-step-2').slideDown().addClass('show');
          $('#apointment-step-3').slideDown().addClass('show');
          $('#apointment-step-2 .apointment-step__inner').slideDown();
        }
      });
    });
  }

  // стилизация скролла вертикального
  public verticalScroll = function(){
    $(document).ready(function(){
      $('.baron-column').each(function(i, t){
        baron({
          root: $(t)[0],
          scroller: '.baron__scroller',
          bar: '.baron__bar',
          scrollingCls: '_scrolling',
          draggingCls: '_dragging'
        });
      });
    });
  }

  // стилизация скролла горизонтального
  public horizontScroll = function(){
    $('.baron-row').each(function(i, t){
      baron({
        root: $(t)[0],
        scroller: '.baron__scroller',
        bar: '.baron__bar',
        scrollingCls: '_scrolling',
        draggingCls: '_dragging',
        direction: 'h',
        rtl: false
      });
    });
  }

  // Скрытие лишних элементов навигации табов в блок "ещё"
  public hiddenTabs = function(){
    $(document).ready(function(){
      if ($(window).outerWidth() > 991) {
        setTimeout(function(){

          $('.nav-tabs').each(function(i, t){

            let width = 0;
            const maxWidth = $(t).outerWidth(); // -100 для кнопки раскрытия
            const add = [];

            $(t).find('.nav-item').each(function(i2, t2){
              width += $(t2).outerWidth(true);
              if (width > maxWidth) {
                add.push($(t2));
              }
            });

            if (width > maxWidth) {

              const tmp = '<div class="nav-tabs__add">'
                + '<button class="nav-tabs__add-btn">Ещё<svg><use xlink:href="#tabs-add"></use></svg></button>'
                + '<div class="nav-tabs__add-list">'
                + '</div>'
                + '</div>';

              $(t).append(tmp).find('.nav-tabs__add-list').append(add).prev('.nav-tabs__add-btn').click(function(){
                $(this).toggleClass('js--show');
              });
              $(t).closest('.baron').addClass('tabs-w-adds');

            }

          });
        }, 1);

      }
    });
  }

  public styleScroll = function(){
    $(document).ready(function(){
      // Стилизация селектов и инициализация внутри стилизации скролла
    $('.chosen').chosen({ disable_search_threshold: 10, inherit_select_classes: 1, no_results_text: 'Совпадений не найдено:', placeholder_text_single: 'Выбрать значение', placeholder_text_multiple: 'Выбрать несколько значений' })
      .on('chosen:showing_dropdown', function(){
        if ($(this).next().find('.baron').outerHeight() > 200) {
          $(this).next().addClass('baron-need');
          baron({
            root: $(this).next().find('.baron-column'),
            scroller: '.baron__scroller',
            bar: '.baron__bar',
            scrollingCls: '_scrolling',
            draggingCls: '_dragging'
          });
        }
      });
    });
  }

  public setTelMask = function(){
    $('[type="tel"]').mask('+7 (999) 999 99-99');
  }
}
// function ym(arg0: number, arg1: string, arg2: { defer: boolean; clickmap: boolean; trackLinks: boolean; accurateTrackBounce: boolean; webvisor: boolean; }) {
//   throw new Error('Function not implemented.');
// }

